<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ 'Add New Baritastic Program' }}</h1>
        </div>
        <baritastic-program-form ref="form" @add="add" form-type="store" :loader="loader" />
    </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import BaritasticProgramForm from "@/views/Components/SuperAdmin/BaritasticProgram/Form";
export default {
    mixins: [generateErrorMessageMixin],
    components: {
        BaritasticProgramForm,
        BackButton
    },
    data() {
        return {
            loader: false,
        };
    },
    methods: {
        async add(fd) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('BaritasticProgramModule/_storeBaritasticProgram', { fd });
                let { data: { data, message } } = response;
                this.$notify.success({
                    title: 'Baritastic Program',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$router.push({name:'admin.baritastic_program'})
            } catch (error) {
                this.showErrorOnForm(error, 'Baritastic Program', validation)
            }
            this.loader = false;
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  